


















































































































import { Vue, Component } from 'vue-property-decorator'
import reportService, {
  BaseDayProfit,
  DayProfitResult,
  ProfitCalc
} from '@/services/reportService'
import LineChart from '@/views/Report/LineChart.vue'
import utility from '@/common/utility'

let pageTrades: DayProfitResult = null

@Component({
  components: {
    LineChart
  }
})
export default class Profit extends Vue {
  data: { Day: string; Stk?: BaseDayProfit; Opt?: BaseDayProfit; Dividend?: number }[] = []
  includeDividends = false
  includeStock = true
  includeOption = true

  $refs: {
    chart: LineChart;
  }

  search: { selectedSymbols: string[]; start: string; end: string } = {
    selectedSymbols: new Array<string>(),
    start: null,
    end: null
  }

  async mounted () {
    await this.loadData()
  }

  async loadData () {
    const res = await reportService.getDayProfits(this.search.selectedSymbols, this.search.start, this.search.end)
    if (res.Error) {
      await this.$alert(res.Error)
      return
    }
    pageTrades = res.Result
    await this.loadDividend()
    this.prepareData()
  }

  async loadDividend () {
    const data = await reportService.getDividends(false)
    if (data.Result) {
      pageTrades.Dividend = data.Result
    }
  }

  getDividendAmt (day: string): number {
    const divs = pageTrades.Dividend.filter(p => p.SettleDate === day)
    if (divs.length > 0) {
      let sum = 0
      for (const divItem of divs) sum += divItem.Amount / (divItem.Rate / 100.0)
      return sum
    }
    return null
  }

  prepareData () {
    const stks = this.getDayRes(pageTrades.Stock)
    const opts = this.getDayRes(pageTrades.Option)
    if (this.includeStock && this.includeOption) {
      const tableData: { Day: string; Stk?: BaseDayProfit; Opt?: BaseDayProfit; Dividend?: number }[] = []
      for (const dayItem of stks) {
        tableData.push({ Day: dayItem.Day, Stk: dayItem })
      }
      for (const dayItem of opts) {
        const findTable = tableData.find(p => p.Day === dayItem.Day)
        if (findTable == null) {
          tableData.push({ Day: dayItem.Day, Opt: dayItem })
        } else {
          findTable.Opt = dayItem
        }
      }
      utility.sortByFiled(tableData, 'Asc', p => utility.parseDate(p.Day))
      for (let i = 1; i < tableData.length; i++) {
        if (!tableData[i].Opt) {
          tableData[i].Opt = tableData[i - 1].Opt
        }
        if (!tableData[i].Stk) {
          tableData[i].Stk = tableData[i - 1].Stk
        }
      }
      tableData.forEach(p => {
        p.Dividend = this.getDividendAmt(p.Day)
      })
      this.data = utility.freezeArrayAndItems(tableData)

      const chartData: BaseDayProfit[] = []
      for (const item of tableData) {
        chartData.push({
          Day: item.Day,
          Realized: (item.Stk?.Realized || 0) + (item.Opt?.Realized || 0),
          TotalRealized: (item.Stk?.TotalRealized || 0) + (item.Opt?.TotalRealized || 0),
          Unrealized: (item.Stk?.Unrealized || 0) + (item.Opt?.Unrealized || 0)
        })
      }
      this.$refs.chart.initChart(chartData)
    } else if (this.includeStock) {
      this.$refs.chart.initChart(stks)
      this.data = utility.freezeArrayAndItems(stks.map(p => {
        return { Day: p.Day, Stk: p, Dividend: p.Dividend }
      }))
    } else if (this.includeOption) {
      this.$refs.chart.initChart(opts)
      this.data = utility.freezeArrayAndItems(opts.map(p => {
        return { Day: p.Day, Opt: p }
      }))
    } else {
      this.$refs.chart.initChart([])
      this.data = []
    }
  }

  private getDayRes (data: ProfitCalc): BaseDayProfit[] {
    const dayRes: BaseDayProfit[] = []
    for (const symbolItem of data.Profits) {
      for (const dayItem of symbolItem.DayProfit) {
        const find = dayRes.find(p => p.Day === dayItem.Day)
        if (find == null) {
          dayRes.push({
            Day: dayItem.Day,
            Realized: dayItem.Realized,
            Unrealized: dayItem.Unrealized,
            RealizedDesc: dayItem.RealizedDesc || '',
            UnrealizedDesc: dayItem.UnrealizedDesc || ''
          })
        } else {
          find.Realized += dayItem.Realized
          find.Unrealized += dayItem.Unrealized
          if (dayItem.RealizedDesc) {
            find.RealizedDesc += dayItem.RealizedDesc
          }
          if (dayItem.UnrealizedDesc) {
            find.UnrealizedDesc += dayItem.UnrealizedDesc
          }
        }
      }
    }
    utility.sortByFiled(dayRes, 'Asc', p => utility.parseDate(p.Day))
    let amt = 0
    for (const item of dayRes) {
      if (this.includeDividends) {
        item.Dividend = this.getDividendAmt(item.Day)
        if (item.Dividend) {
          amt += item.Dividend
          item.RealizedDesc += `Dividend = ${item.Dividend} (USD)`
        }
      }
      amt += item.Realized
      item.TotalRealized = amt
      item.TotalRealized = Math.round(item.TotalRealized)
      item.Unrealized = Math.round(item.Unrealized)
    }
    return utility.freezeArrayAndItems(dayRes)
  }

  destroyed () {
    pageTrades = null
  }
}
