








import * as echarts from 'echarts'
import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from '@/components/resize'
import { BaseDayProfit } from '@/services/reportService'

@Component({
  name: 'LineChart'
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: 'chart' }) private className!: string
  @Prop({ default: 'chart' }) private id!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '800px' }) private height!: string

  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  }

  public initChart (data: BaseDayProfit[] = []) {
    this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement)
    this.chart.setOption({
      title: {
        top: 20,
        text: '',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 16
        },
        left: '1%'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        top: 20,
        icon: 'rect',
        itemWidth: 14,
        itemHeight: 5,
        itemGap: 13,
        data: ['Realized', 'UnRealized', 'Total'],
        right: '4%',
        textStyle: {
          fontSize: 12,
          color: '#333333'
        }
      },
      grid: {
        top: 100,
        left: '2%',
        right: '2%',
        bottom: '2%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: '#57617B'
          }
        },
        data: data.map(p => p.Day)
      }],
      yAxis: [{
        type: 'value',
        name: 'USD',
        nameTextStyle: {
          fontSize: 16,
          align: 'right',
          padding: [0, 20, 0, 0]
        },
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#57617B'
          }
        },
        axisLabel: {
          margin: 10,
          fontSize: 14
        },
        splitLine: {
          lineStyle: {
            color: '#DDDDDD'
          }
        }
      }],
      series: [{
        name: 'Realized',
        type: 'line',
        smooth: false,
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: false,
        lineStyle: {
          width: 2
        },
        itemStyle: {
          color: 'rgb(137,189,27)',
          borderColor: 'rgba(137,189,2,0.27)',
          borderWidth: 12
        },
        data: data.map(p => Math.floor(p.TotalRealized * 100) / 100)
      }, {
        name: 'UnRealized',
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: false,
        lineStyle: {
          width: 2
        },
        itemStyle: {
          color: 'rgb(0,136,212)',
          borderColor: 'rgba(0,136,212,0.2)',
          borderWidth: 12
        },
        data: data.map(p => Math.floor(p.Unrealized * 100) / 100)
      }, {
        name: 'Total',
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: false,
        lineStyle: {
          width: 2
        },
        itemStyle: {
          color: 'rgb(219,50,51)',
          borderColor: 'rgba(219,50,51,0.2)',
          borderWidth: 12
        },
        data: data.map(p => Math.floor((p.TotalRealized + p.Unrealized) * 100) / 100)
      }]
    })
  }
}

